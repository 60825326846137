import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-five-customer',
  templateUrl: './section-five-customer.component.html'
})
export class SectionFiveCustomerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
