import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-eight-award',
  templateUrl: './section-eight-award.component.html',
  styleUrls: ['./section-eight-award.component.css']
})
export class SectionEightAwardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
